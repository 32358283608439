import * as React from "react"
import {Link, graphql} from "gatsby"

import Tags from "../components/tagList"
import Twitter from '../components/block/twitter'
import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "katex/dist/katex.min.css"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
const BASEURL = 'https://podman.dev'

const BlogPostTemplate = ({data, location}) => {
    const post = data.markdownRemark
    const { featuredImage, tags } = post.frontmatter
    const siteTitle = data.site.siteMetadata?.title || `Title`
    const image = getImage(featuredImage.src)
    const {previous, next} = data

    return (
        <Layout location={location} title={siteTitle}>
            <SEO
                title={post.frontmatter.title}
                description={post.frontmatter.description || post.excerpt}
                image={image.images.fallback.src}
            />
            <article
                className="blog-post"
                itemScope
                itemType="http://schema.org/Article"
            >
                <header>
                    <GatsbyImage image={image} alt={featuredImage.alt} />
                    <h1 itemProp="headline">{post.frontmatter.title}</h1>
                </header>
                <section
                    dangerouslySetInnerHTML={{__html: post.html}}
                    itemProp="articleBody"
                />
                <div style={{textAlign:'center',margin:'5rem 0'}}>
                  <p>ポチッと頂けると励みになります<span role="img">😄</span></p>
                <a href="https://stock.blogmura.com/americastock/ranking/in?p_cid=11096131" target="_blank" rel="noreferrer"><img src="https://b.blogmura.com/stock/americastock/88_31.gif" width="88" height="31" border="0" alt="にほんブログ村 株ブログ 米国株へ" /></a>
                </div>
                <Tags tags={tags}/>
                <Twitter url={BASEURL + data['sitePage'].path}/>
                <hr/>
                <footer>
                    <Bio/>
                </footer>
            </article>
            <nav className="blog-post-nav">
                <ul
                    style={{
                        display: `flex`,
                        flexWrap: `wrap`,
                        justifyContent: `space-between`,
                        listStyle: `none`,
                        padding: 0,
                    }}
                >
                    <li>
                        {previous && (
                            <Link to={previous.fields.slug} rel="prev">
                                ← {previous.frontmatter.title}
                            </Link>
                        )}
                    </li>
                    <li>
                        {next && (
                            <Link to={next.fields.slug} rel="next">
                                {next.frontmatter.title} →
                            </Link>
                        )}
                    </li>
                </ul>
            </nav>
        </Layout>
    )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    sitePage{
      path
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        tags
        featuredImage {
            src {
              childImageSharp {
                gatsbyImageData(
                  formats: [AUTO, WEBP, AVIF]
                 )
              }
            }
            alt
         }
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
