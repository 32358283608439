import * as React from "react"
import { Link } from "gatsby"

const tagList = ({tags}) => {

    return (
        <ul style={{listStyle:'none',display:'flex',justifyContent:'flex-start'}}>
            {tags.map((tag) => <li key={tag} style={{
                margin:'1rem',
                padding:'.3rem .5rem',
                borderRadius: '5px',
                background: '#fff',
                boxShadow: '5px 5px 10px #cccccc, -5px -5px 10px #ffffff'
            }}><Link style={{textDecoration:'none',display:'block'}} to={`/tags/${tag}`}>{tag}</Link></li>)}
        </ul>
    )
}

export default tagList
